<template>
  <b-card id="real-estat-accommodation-summary-card" no-body>
    <b-card-header>
      <b-card-title>Resum</b-card-title>
      <b-card-title>
        <b-form-checkbox
          v-model="trending"
          class="custom-control-primary"
          switch
          @change="onTrendingSwitched"
        >
          <span class="switch-icon-left">
            <feather-icon icon="StarIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="StarIcon" />
          </span>
        </b-form-checkbox>
      </b-card-title>
    </b-card-header>

    <b-card-body>
      <b-row class="mb-2">
        <!-- PICUTRE -->
        <b-col cols="12" md="6" lg="4" xl="3" class="mb-3 mb-md-0">
          <b-img
            :src="
              coverPicture ||
              require('@/assets/foravila/images/accommodation-placeholder.jpg')
            "
            fluid-grow
            rounded
          />
        </b-col>

        <!-- DETAILS -->
        <b-col cols="12" md="6" lg="8" xl="9">
          <b-row>
            <!-- STATUS -->
            <b-col :cols="editMode ? 12 : 6" sm="4" md="6" xl="4" class="mb-2">
              <h6 class="mb-75">Estat</h6>
              <v-select
                v-if="editMode"
                v-model="status"
                placeholder="Selecciona una estat"
                :options="statusOptions"
                :reduce="(option) => option.value"
              />
              <b-card-text v-else>
                {{ statusText }}
              </b-card-text>
            </b-col>

            <!-- REFERENCE -->
            <b-col :cols="editMode ? 12 : 6" sm="4" md="6" xl="4" class="mb-2">
              <h6 class="mb-75">Referència</h6>
              <b-form-input
                v-if="editMode"
                v-model="reference"
                placeholder="Referència de l'allotjament"
              />
              <b-card-text v-else>
                {{ accommodation.reference }}
              </b-card-text>
            </b-col>

            <!-- PRICE -->
            <b-col :cols="editMode ? 12 : 6" sm="4" md="6" xl="4" class="mb-2">
              <h6 class="mb-75">Preu</h6>
              <b-form-group v-if="editMode">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-model="userPrice"
                    type="number"
                    placeholder="Preu de venda"
                  />
                  <b-input-group-append is-text> € </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-card-text v-else>
                {{ priceText }}
              </b-card-text>
            </b-col>

            <!-- TYPE -->
            <b-col :cols="editMode ? 12 : 6" sm="4" md="6" xl="4" class="mb-2">
              <h6 class="mb-75">Tipus</h6>

              <v-select
                v-if="editMode"
                v-model="type"
                placeholder="Selecciona un tipus d'allotjament"
                :options="accommodationTypeOptions"
                :reduce="(option) => option.value"
              />
              <b-card-text v-else>
                {{ accommodationTypeName }}
              </b-card-text>
            </b-col>

            <!-- BEDROOMS -->
            <b-col
              v-if="
                accommodationTypeHasArrangement(accommodation.type) &&
                accommodationTypeHasArrangement(type)
              "
              cols="6"
              sm="4"
              md="6"
              xl="4"
              class="mb-2"
            >
              <h6 class="mb-75">Dormitoris</h6>
              <b-form-spinbutton
                v-if="editMode"
                v-model="bedrooms"
                min="1"
                max="20"
                step="1"
              />
              <b-card-text v-else>
                {{ accommodation.bedrooms }}
              </b-card-text>
            </b-col>

            <!-- BATHROOMS -->
            <b-col
              v-if="
                accommodationTypeHasArrangement(accommodation.type) &&
                accommodationTypeHasArrangement(type)
              "
              cols="6"
              sm="4"
              md="6"
              xl="4"
              class="mb-2"
            >
              <h6 class="mb-75">Banys</h6>
              <b-form-spinbutton
                v-if="editMode"
                v-model="bathrooms"
                min="1"
                max="20"
                step="1"
              />
              <b-card-text v-else>
                {{ accommodation.bathrooms }}
              </b-card-text>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!-- BUTTONS -->
      <b-row class="d-flex justify-content-between justify-content-sm-end">
        <b-col v-if="editMode" cols="6" sm="auto">
          <b-button block variant="light" @click="editMode = false">
            Cancel·la
          </b-button>
        </b-col>
        <b-col v-if="editMode" cols="6" sm="auto">
          <b-button block variant="primary" @click="onSaveButtonClicked">
            Guarda
          </b-button>
        </b-col>
        <b-col v-if="!editMode" cols="12" sm="auto">
          <b-button block variant="primary" @click="editMode = true">
            Edita
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BCardText,
  BRow,
  BCol,
  BImg,
  BButton,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormGroup,
  BFormSpinbutton,
  BFormCheckbox,
} from "bootstrap-vue";
import {
  getAccommodationTypeName,
  getRealEstateStatusName,
  notifyError,
  notifySuccess,
} from "@/utils/methods";
import { formatCurrency } from "@/utils/formatters";
import {
  realEstateStatusOptions,
  realEstateAccommodationTypeOptions,
} from "@/utils/select-options";
import vSelect from "vue-select";

export default {
  name: "SummaryCard",
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    BImg,
    BButton,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    vSelect,
    BFormSpinbutton,
    BFormCheckbox,
  },
  data() {
    return {
      editMode: false,
      status: null,
      reference: null,
      userPrice: null,
      type: null,
      bedrooms: null,
      bathrooms: null,
      trending: false,
    };
  },
  computed: {
    accommodation() {
      return this.$store.getters["realEstateAccommodation/accommodation"];
    },
    pictures() {
      return this.$store.getters[
        "realEstateAccommodation/accommodationPictures"
      ];
    },
    coverPicture() {
      const coverPicture = this.pictures.find((p) => p.cover === true);
      if (coverPicture) return coverPicture?.thumbnailUrl;
      return this.accommodation?.coverPicture?.thumbnailUrl;
    },
    statusText() {
      return (
        getRealEstateStatusName(this.accommodation.status) ||
        this.$t("No definit")
      );
    },
    accommodationTypeName() {
      return (
        getAccommodationTypeName(this.accommodation.type, this.$i18n.locale) ||
        this.$t(this.$t("No definit"))
      );
    },
    priceText() {
      return formatCurrency(this.accommodation.price) || this.$t("No definit");
    },
    statusOptions() {
      return realEstateStatusOptions;
    },
    accommodationTypeOptions() {
      return realEstateAccommodationTypeOptions;
    },
    price() {
      if (!this.userPrice) return null;
      return Math.round(this.userPrice * 100);
    },
  },
  watch: {
    editMode(editMode) {
      if (editMode) this.initInputs();
      else this.resetInputs();
    },
  },
  created() {
    this.trending = !!this.accommodation.trending;
  },
  methods: {
    initInputs() {
      this.status = this.accommodation.status;
      this.reference = this.accommodation.reference;
      this.userPrice = this.accommodation.price / 100;
      this.type = this.accommodation.type;
      this.bedrooms = this.accommodation.bedrooms;
      this.bathrooms = this.accommodation.bathrooms;
      this.trending = !!this.accommodation.trending;
    },
    resetInputs() {
      this.status = null;
      this.reference = null;
      this.userPrice = null;
      this.type = null;
      this.bedrooms = null;
      this.bathrooms = null;
      this.trending = !!this.accommodation.trending;
    },
    onTrendingSwitched() {
      if (!this.editMode) {
        this.$store
          .dispatch("realEstateAccommodation/updateAccommodation", {
            uuid: this.accommodation.uuid,
            trending: this.trending || false,
          })
          .then(() =>
            notifySuccess(
              "Allotjament actualitzat",
              "L'allotjament s'ha actualitzat correctament"
            )
          )
          .catch(() =>
            notifyError(
              "Allotjament no actualitzat",
              "L'allotjament no s'ha pogut actualitzar correctament, si us plau, revisa les dades introduïdes"
            )
          );
      }
    },
    onSaveButtonClicked() {
      this.$store.dispatch("app/setLoading", true);

      // TODO: validate fields

      this.$store
        .dispatch("realEstateAccommodation/updateAccommodation", {
          uuid: this.accommodation.uuid,
          status: this.status || "DISABLED",
          reference: this.reference || null,
          // ! `price` parameter must be a string because in the database is stored as a `bigint`
          price: this.price.toString() || null,
          type: this.type || "OTHER",
          bedrooms: this.bedrooms || null,
          bathrooms: this.bathrooms || null,
          trending: this.trending || false,
        })
        .then(() => {
          notifySuccess(
            "Allotjament actualitzat",
            "L'allotjament s'ha actualitzat correctament"
          );
          this.editMode = false;
        })
        .catch(() =>
          notifyError(
            "Allotjament no actualitzat",
            "L'allotjament no s'ha pogut actualitzar correctament, si us plau, revisa les dades introduïdes"
          )
        )
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    accommodationTypeHasArrangement(type) {
      switch (type) {
        case "PLOT":
        case "BUILDABLE_RUSTIC_LAND":
        case "NON_BUILDABLE_RUSTIC_LAND":
          return false;
        default:
          return true;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
