<template>
  <div v-if="accommodation" id="real-estate-accommodations-view">
    <b-tabs>
      <b-tab active>
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>{{ $t("Detalls") }}</span>
        </template>
        <summary-card />
        <location-card />
        <descriptions-card />
        <multimedia-card />
        <amenities-card />
        <delete-card />
      </b-tab>
      <!-- <b-tab>
        <template #title>
          <feather-icon icon="MessageCircleIcon" />
          <span>{{ $t('Missatges') }}</span>
        </template>
      </b-tab> -->
      <!-- <b-tab>
        <template #title>
          <feather-icon icon="BookOpenIcon" />
          <span>{{ $t('Visites') }}</span>
        </template>
      </b-tab> -->
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import SummaryCard from "@/views/real-estate-accommodations/real-estate-accommodation/components/SummaryCard.vue";
import LocationCard from "@/views/real-estate-accommodations/real-estate-accommodation/components/LocationCard.vue";
import DescriptionsCard from "@/views/real-estate-accommodations/real-estate-accommodation/components/DescriptionsCard.vue";
import MultimediaCard from "@/views/real-estate-accommodations/real-estate-accommodation/components/MultimediaCard.vue";
import AmenitiesCard from "@/views/real-estate-accommodations/real-estate-accommodation/components/AmenitiesCard.vue";
import DeleteCard from "@/views/real-estate-accommodations/real-estate-accommodation/components/DeleteCard.vue";
import { notifyError } from "@/utils/methods";

export default {
  name: "RealEstateAccommodationView",
  components: {
    BTabs,
    BTab,
    SummaryCard,
    LocationCard,
    DescriptionsCard,
    MultimediaCard,
    AmenitiesCard,
    DeleteCard,
  },
  data() {
    return {
      accommodaitonNotFound: false,
    };
  },
  computed: {
    loadingAccommodation() {
      return this.$store.getters[
        "realEstateAccommodation/loadingAccommodation"
      ];
    },
    accommodation() {
      return this.$store.getters["realEstateAccommodation/accommodation"];
    },
  },
  watch: {
    accommodation(accommodation) {
      // Set page title
      this.setPageTitle(accommodation);
    },
  },
  mounted() {
    if (!this.loadingAccommodation) this.fetchAccommodation();

    // Set page title
    this.setPageTitle(this.accommodation);
  },
  beforeDestroy() {
    this.$store.dispatch("realEstateAccommodation/reset");
    this.setPageTitle(null);
  },
  methods: {
    fetchAccommodation() {
      const { accommodationUuid } = this.$route.params;

      this.$store
        .dispatch(
          "realEstateAccommodation/fetchAccommodation",
          accommodationUuid
        )
        .then(() => {
          // Basic accommodation data already fetched
          // now fetch the rest of the data
          this.$store.dispatch(
            "realEstateAccommodation/fetchAccommodationPictures",
            accommodationUuid
          );
          this.$store.dispatch(
            "realEstateAccommodation/fetchAccommodationAmenities",
            accommodationUuid
          );
        })
        .catch((error) => {
          if (error.response.status === 404) this.accommodaitonNotFound = true;
          notifyError(
            this.$t("errors.fetchAccommodation.title"),
            this.$t("errors.fetchAccommodation.description")
          );
          // TODO: log the error with Sentry
        });
    },
    setPageTitle(accommodation) {
      this.$store.dispatch(
        "app/setPageTitle",
        accommodation?.reference || null
      );
      this.$store.dispatch(
        "app/setPageSubtitle",
        accommodation?.location?.city || null
      );
    },
  },
};
</script>

<style></style>
