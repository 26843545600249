<template>
  <b-card id="amenities-card" title="Característiques">
    <!-- SURFACES -->
    <b-row class="mb-2">
      <b-col cols="12">
        <h5 class="mb-2"><feather-icon icon="CropIcon" /> Superfícies</h5>
      </b-col>
      <b-col cols="12">
        <b-row>
          <b-col cols="6" md="4" lg="3" class="mb-1">
            <h6>Solar</h6>
            <b-input-group v-if="editMode">
              <b-form-input
                v-model="plotSize"
                placeholder="Superfície del solar en m2"
                type="number"
              />
              <b-input-group-append is-text>
                m<sup>2</sup>
              </b-input-group-append>
            </b-input-group>
            <b-card-text v-else>
              {{ plotSizeAmenityText }}
            </b-card-text>
          </b-col>
          <b-col cols="6" md="4" lg="3" class="mb-1">
            <h6>Habitatge</h6>
            <b-input-group v-if="editMode">
              <b-form-input
                v-model="accommodationSize"
                placeholder="Superfície de l'habitatge"
                type="number"
              />
              <b-input-group-append is-text>
                m<sup>2</sup>
              </b-input-group-append>
            </b-input-group>
            <b-card-text v-else>
              {{ accommodationSizeAmenityText }}
            </b-card-text>
          </b-col>
          <b-col cols="6" md="4" lg="3" class="mb-1">
            <h6>Jardí</h6>
            <b-input-group v-if="editMode">
              <b-form-input
                v-model="gardenSize"
                placeholder="Superfície del jardí"
                type="number"
              />
              <b-input-group-append is-text>
                m<sup>2</sup>
              </b-input-group-append>
            </b-input-group>
            <b-card-text v-else>
              {{ gardenSizeAmenityText }}
            </b-card-text>
          </b-col>
          <b-col cols="6" md="4" lg="3" class="mb-1">
            <h6>Terrassa</h6>
            <b-input-group v-if="editMode">
              <b-form-input
                v-model="terraceSize"
                placeholder="Superfície de la terrassa"
                type="number"
              />
              <b-input-group-append is-text>
                m<sup>2</sup>
              </b-input-group-append>
            </b-input-group>
            <b-card-text v-else>
              {{ terraceSizeAmenityText }}
            </b-card-text>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- POOL -->
    <b-row class="mb-3">
      <b-col cols="12">
        <h5 class="mb-2"><feather-icon icon="LifeBuoyIcon" /> Piscina</h5>
      </b-col>
      <b-col cols="12">
        <b-row>
          <b-col cols="6" md="4" lg="3" class="mb-1">
            <h6>Piscina (superfície)</h6>
            <div v-if="editMode">
              <b-input-group class="mb-1">
                <b-form-input
                  v-model="poolWidth"
                  placeholder="Ample"
                  type="number"
                />
                <b-input-group-append is-text> m </b-input-group-append>
              </b-input-group>
              <b-input-group>
                <b-form-input
                  v-model="poolLength"
                  placeholder="Llarg"
                  type="number"
                />
                <b-input-group-append is-text> m </b-input-group-append>
              </b-input-group>
            </div>
            <b-card-text v-else>
              {{ poolSizeText }}
            </b-card-text>
          </b-col>
          <b-col cols="6" md="4" lg="3" class="mb-1">
            <h6>Piscina (profunditat)</h6>
            <div v-if="editMode">
              <b-input-group class="mb-1">
                <b-form-input
                  v-model="poolMinDepth"
                  placeholder="Min"
                  type="number"
                />
                <b-input-group-append is-text> cm </b-input-group-append>
              </b-input-group>
              <b-input-group>
                <b-form-input
                  v-model="poolMaxDepth"
                  placeholder="Max"
                  type="number"
                />
                <b-input-group-append is-text> cm </b-input-group-append>
              </b-input-group>
            </div>
            <b-card-text v-else>
              {{ poolDepthText }}
            </b-card-text>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="d-flex justify-content-end">
      <b-col v-if="editMode" cols="6" sm="auto">
        <b-button block variant="light" @click="onCancelButtonClicked">
          Cancel·la
        </b-button>
      </b-col>
      <b-col v-if="editMode" cols="6" sm="auto">
        <b-button block variant="primary" @click="onSaveButtonClicked">
          Guarda
        </b-button>
      </b-col>
      <b-col v-if="!editMode" cols="12" sm="auto">
        <b-button block variant="primary" @click="onEditButtonClicked">
          Edita
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
} from "bootstrap-vue";
import {
  getAmenityTextAndUnit,
  notifyError,
  notifySuccess,
} from "@/utils/methods";

export default {
  name: "AmenitiesCard",
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
  },
  data() {
    return {
      editMode: false,
      plotSize: null,
      accommodationSize: null,
      gardenSize: null,
      terraceSize: null,
      poolWidth: null,
      poolLength: null,
      poolMinDepth: null,
      poolMaxDepth: null,
      selectedAmenities: [],
    };
  },
  computed: {
    accommodation() {
      return this.$store.getters["realEstateAccommodation/accommodation"];
    },
    accommodationAmenities() {
      return this.$store.getters[
        "realEstateAccommodation/accommodationAmenities"
      ];
    },
    plotSizeAmenity() {
      return this.accommodationAmenities.find(
        (a) => a.amenity.code === "PLOT_SIZE"
      );
    },
    plotSizeAmenityText() {
      const text = getAmenityTextAndUnit(this.plotSizeAmenity);
      return text || "No definit";
    },
    accommodationSizeAmenity() {
      return this.accommodationAmenities.find(
        (a) => a.amenity.code === "ACCOMMODATION_SIZE"
      );
    },
    accommodationSizeAmenityText() {
      const text = getAmenityTextAndUnit(this.accommodationSizeAmenity);
      return text || "No definit";
    },
    gardenSizeAmenity() {
      return this.accommodationAmenities.find(
        (a) => a.amenity.code === "GARDEN_SIZE"
      );
    },
    gardenSizeAmenityText() {
      const text = getAmenityTextAndUnit(this.gardenSizeAmenity);
      return text || "No definit";
    },
    terraceSizeAmenity() {
      return this.accommodationAmenities.find(
        (a) => a.amenity.code === "TERRACE_SIZE"
      );
    },
    terraceSizeAmenityText() {
      const text = getAmenityTextAndUnit(this.terraceSizeAmenity);
      return text || "No definit";
    },
    poolLengthAmenity() {
      return this.accommodationAmenities.find(
        (a) => a.amenity.code === "POOL_LENGTH"
      );
    },
    poolLengthAmenityText() {
      return getAmenityTextAndUnit(this.poolLengthAmenity);
    },
    poolWidthAmenity() {
      return this.accommodationAmenities.find(
        (a) => a.amenity.code === "POOL_WIDTH"
      );
    },
    poolWidthAmenityText() {
      return getAmenityTextAndUnit(this.poolWidthAmenity);
    },
    poolSizeText() {
      if (!this.poolLengthAmenityText || !this.poolWidthAmenityText)
        return "No definit";
      return `${this.poolLengthAmenityText} x ${this.poolWidthAmenityText}`;
    },
    poolMinDepthAmenity() {
      return this.accommodationAmenities.find(
        (a) => a.amenity.code === "POOL_MIN_DEPTH"
      );
    },
    poolMinDepthAmenityText() {
      return getAmenityTextAndUnit(this.poolMinDepthAmenity);
    },
    poolMaxDepthAmenity() {
      return this.accommodationAmenities.find(
        (a) => a.amenity.code === "POOL_MAX_DEPTH"
      );
    },
    poolMaxDepthAmenityText() {
      return getAmenityTextAndUnit(this.poolMaxDepthAmenity);
    },
    poolDepthText() {
      if (!this.poolMinDepthAmenityText || !this.poolMaxDepthAmenityText)
        return "No definit";
      return `${this.poolMinDepthAmenityText} x ${this.poolMaxDepthAmenityText}`;
    },
    updatableAmenities() {
      // const manualAmenities = [
      //   'PLOT_SIZE',
      //   'ACCOMMODATION_SIZE',
      //   'GARDEN_SIZE',
      //   'TERRACE_SIZE',
      //   'POOL_LENGTH',
      //   'POOL_WIDTH',
      //   'POOL_MIN_DEPTH',
      //   'POOL_MAX_DEPTH',
      // ]

      const amenities = [];

      // Checkbox amenities
      // if (this.selectedAmenities.length > 0) {
      //   this.selectedAmenities.forEach(a => {
      //     if (!manualAmenities.includes(a)) {
      //       amenities.push({
      //         code: a,
      //         value: null,
      //         units: null,
      //       })
      //     }
      //   })
      // }

      // Sizes amenities
      amenities.push({
        code: "PLOT_SIZE",
        value: this.plotSize,
        units: "m2",
      });
      amenities.push({
        code: "ACCOMMODATION_SIZE",
        value: this.accommodationSize,
        units: "m2",
      });
      amenities.push({
        code: "GARDEN_SIZE",
        value: this.gardenSize,
        units: "m2",
      });
      amenities.push({
        code: "TERRACE_SIZE",
        value: this.terraceSize,
        units: "m2",
      });
      amenities.push({
        code: "POOL_LENGTH",
        value: this.poolLength,
        units: "m",
      });
      amenities.push({
        code: "POOL_WIDTH",
        value: this.poolWidth,
        units: "m",
      });
      amenities.push({
        code: "POOL_MIN_DEPTH",
        value: this.poolMinDepth,
        units: "cm",
      });
      amenities.push({
        code: "POOL_MAX_DEPTH",
        value: this.poolMaxDepth,
        units: "cm",
      });

      return amenities;
    },
  },
  methods: {
    initInputs() {
      this.plotSize = this.plotSizeAmenity?.value || null;
      this.accommodationSize = this.accommodationSizeAmenity?.value || null;
      this.gardenSize = this.gardenSizeAmenity?.value || null;
      this.terraceSize = this.terraceSizeAmenity?.value || null;
      this.poolWidth = this.poolWidthAmenity?.value || null;
      this.poolLength = this.poolLengthAmenity?.value || null;
      this.poolMinDepth = this.poolMinDepthAmenity?.value || null;
      this.poolMaxDepth = this.poolMaxDepthAmenity?.value || null;
    },
    resetInputs() {
      this.plotSize = null;
      this.accommodationSize = null;
      this.gardenSize = null;
      this.terraceSize = null;
      this.poolWidth = null;
      this.poolLength = null;
      this.poolMinDepth = null;
      this.poolMaxDepth = null;
    },
    onEditButtonClicked() {
      this.initInputs();
      this.editMode = true;
    },
    onCancelButtonClicked() {
      this.editMode = false;
      this.resetInputs();
    },
    onSaveButtonClicked() {
      this.$store.dispatch("app/setLoading", true);

      // TODO: validate fields

      this.$store
        .dispatch("realEstateAccommodation/updateAccommodationAmenities", {
          accommodationUuid: this.accommodation.uuid,
          amenities: this.updatableAmenities,
        })
        .then(async () => {
          await this.$store.dispatch(
            "realEstateAccommodation/fetchAccommodationAmenities",
            this.accommodation.uuid
          );
          notifySuccess(
            "Allotjament actualitzat",
            "L'allotjament s'ha actualitzat correctament"
          );
          this.editMode = false;
        })
        .catch(() =>
          notifyError(
            "Allotjament no actualitzat",
            "L'allotjament no s'ha pogut actualitzar correctament, si us plau, revisa les dades introduïdes"
          )
        )
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>
