<template>
  <b-card id="descriptions-card" title="Descripcions">
    <!-- ACCOMMODATION DESCRIPTIONS -->
    <b-row class="mb-3">
      <b-col cols="12" class="mb-2">
        <h5><feather-icon icon="HomeIcon" /> Allotjament</h5>
      </b-col>
      <b-col cols="12">
        <b-tabs>
          <template v-if="editMode" #tabs-end>
            <b-nav-item role="presentation">
              <b-button
                variant="primary"
                size="sm"
                @click.prevent="onNewAccommodationDescriptionButtonClicked"
              >
                <feather-icon icon="PlusIcon" />
                Nova <span class="d-none d-md-inline">descripció</span>
              </b-button>
            </b-nav-item>
          </template>
          <b-tab
            v-for="description in editedAccommodationDescriptions"
            :key="`accommodation-description-tab-${description.uuid}`"
            class="description-tab"
          >
            <template #title>
              <span>{{ getDescriptionLanguageCode(description) }}</span>
            </template>
            <description-form
              v-if="editMode"
              :description="description"
              accommodation-description
              @change="onAccommodationDescriptionChanged"
              @delete="onDeleteAccommodationDescriptionButtonClicked"
            />
            <div v-else class="description-text">
              <div class="font-weight-bold mb-1">
                {{ description.title }}
              </div>
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div v-html="description.content" />
            </div>
          </b-tab>
        </b-tabs>
        <b-alert
          v-if="editedAccommodationDescriptions.length === 0"
          variant="primary"
          show
        >
          <div class="alert-body">
            Encara no s'han creat descripcions per l'allotjament
          </div>
        </b-alert>
      </b-col>
    </b-row>

    <!-- NEIGHBOURHOOD DESCRIPTIONS -->
    <b-row class="mb-2">
      <b-col cols="12" class="mb-2">
        <h5><feather-icon icon="MapIcon" /> {{ $t("Barri") }}</h5>
      </b-col>
      <b-col cols="12">
        <b-tabs>
          <template v-if="editMode" #tabs-end>
            <b-nav-item role="presentation">
              <b-button
                variant="primary"
                size="sm"
                @click.prevent="onNewNeighbourhoodDescriptionButtonClicked"
              >
                <feather-icon icon="PlusIcon" />
                Nova
                <span class="d-none d-md-inline">descripció</span>
              </b-button>
            </b-nav-item>
          </template>
          <b-tab
            v-for="description in editedNeighbourhoodDescriptions"
            :key="`neighbourhood-description-tab-${description.uuid}`"
            class="description-tab"
          >
            <template #title>
              <span>{{ getDescriptionLanguageCode(description) }}</span>
            </template>
            <description-form
              v-if="editMode"
              :description="description"
              neighbourhood-description
              @change="onNeighbourhoodDescriptionChanged"
              @delete="onDeleteNeighbourhoodDescriptionButtonClicked"
            />
            <div v-else class="description-text">
              <div class="font-weight-bold mb-1">
                {{ description.title }}
              </div>
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div v-html="description.content" />
            </div>
          </b-tab>
        </b-tabs>
        <b-alert
          v-if="editedNeighbourhoodDescriptions.length === 0"
          variant="primary"
          show
        >
          <div class="alert-body">
            Encara no s'han creat descripcions per el barri
          </div>
        </b-alert>
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="d-flex justify-content-between justify-content-sm-end">
      <b-col v-if="editMode" cols="6" sm="auto">
        <b-button block variant="light" @click="onCancelButtonClicked">
          Cancel·la
        </b-button>
      </b-col>
      <b-col v-if="editMode" cols="6" sm="auto">
        <b-button block variant="primary" @click="onSaveButtonClicked">
          Guarda
        </b-button>
      </b-col>
      <b-col v-if="!editMode" cols="12" sm="auto">
        <b-button block variant="primary" @click="onEditButtonClicked">
          Edita
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BTabs,
  BTab,
  BRow,
  BCol,
  BButton,
  BNavItem,
  BAlert,
} from "bootstrap-vue";
import { notifySuccess, notifyError } from "@/utils/methods";
import { v4 as uuidv4 } from "uuid";
import DescriptionForm from "@/views/real-estate-accommodations/real-estate-accommodation/components/DescriptionForm.vue";

export default {
  name: "DescriptionsCard",
  components: {
    BCard,
    BTabs,
    BTab,
    BRow,
    BCol,
    BButton,
    BNavItem,
    DescriptionForm,
    BAlert,
  },
  data() {
    return {
      editMode: false,
      editedAccommodationDescriptions: [],
      editedNeighbourhoodDescriptions: [],
    };
  },
  computed: {
    accommodation() {
      return this.$store.getters["realEstateAccommodation/accommodation"];
    },
    accommodationDescriptions() {
      return this.accommodation.descriptions
        ? this.accommodation.descriptions.filter(
            (d) => d.scope === "ACCOMMODATION"
          )
        : [];
    },
    neighbourhoodDescriptions() {
      return this.accommodation.descriptions
        ? this.accommodation.descriptions.filter(
            (d) => d.scope === "NEIGHBOURHOOD"
          )
        : [];
    },
  },
  created() {
    this.initInputs();
  },
  methods: {
    getDescriptionLanguageCode(description) {
      return description.language
        ? description.language.toUpperCase()
        : "Selecciona un idioma";
    },
    initInputs() {
      this.editedAccommodationDescriptions = [];
      this.editedNeighbourhoodDescriptions = [];
      if (this.accommodation.descriptions?.length > 0) {
        this.accommodation.descriptions.forEach((description) => {
          if (description.scope === "ACCOMMODATION") {
            this.editedAccommodationDescriptions.push({
              language: description.language,
              title: description.title,
              content: description.content,
              scope: description.scope,
              uuid: description.uuid,
              new: false,
            });
          }
          if (description.scope === "NEIGHBOURHOOD") {
            this.editedNeighbourhoodDescriptions.push({
              language: description.language,
              title: description.title,
              content: description.content,
              scope: description.scope,
              uuid: description.uuid,
              new: false,
            });
          }
        });
      }
    },
    onEditButtonClicked() {
      this.initInputs();
      this.editMode = true;
    },
    onCancelButtonClicked() {
      this.editMode = false;
      this.initInputs();
    },
    onSaveButtonClicked() {
      this.$store.dispatch("app/setLoading", true);

      // TODO: validate fields

      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("realEstateAccommodation/updateAccommodationDescriptions", {
          accommodation: this.accommodation,
          descriptions: [
            ...this.editedAccommodationDescriptions,
            ...this.editedNeighbourhoodDescriptions,
          ],
        })
        .then(() => {
          notifySuccess(
            "Allotjament actualitzat",
            "L'allotjament s'ha actualitzat correctament"
          );
          this.editMode = false;
        })
        .catch(() =>
          notifyError(
            "Allotjament no actualitzat",
            "L'allotjament no s'ha pogut actualitzar correctament, si us plau, revisa les dades introduïdes"
          )
        )
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    onNewAccommodationDescriptionButtonClicked() {
      this.editedAccommodationDescriptions.push({
        uuid: uuidv4(),
        language: null,
        title: null,
        content: null,
        scope: "ACCOMMODATION",
        new: true,
      });
    },
    onNewNeighbourhoodDescriptionButtonClicked() {
      this.editedNeighbourhoodDescriptions.push({
        uuid: uuidv4(),
        language: null,
        title: null,
        content: null,
        scope: "NEIGHBOURHOOD",
        new: true,
      });
    },
    onAccommodationDescriptionChanged(description) {
      const index = this.editedAccommodationDescriptions.findIndex(
        (d) => d.uuid === description.uuid
      );
      if (index !== -1)
        this.editedAccommodationDescriptions.splice(index, 1, description);
    },
    onDeleteAccommodationDescriptionButtonClicked(uuid) {
      const index = this.editedAccommodationDescriptions.findIndex(
        (d) => d.uuid === uuid
      );
      if (index !== -1) this.editedAccommodationDescriptions.splice(index, 1);
    },
    onNeighbourhoodDescriptionChanged(description) {
      const index = this.editedNeighbourhoodDescriptions.findIndex(
        (d) => d.uuid === description.uuid
      );
      if (index !== -1)
        this.editedNeighbourhoodDescriptions.splice(index, 1, description);
    },
    onDeleteNeighbourhoodDescriptionButtonClicked(uuid) {
      const index = this.editedNeighbourhoodDescriptions.findIndex(
        (d) => d.uuid === uuid
      );
      if (index !== -1) this.editedNeighbourhoodDescriptions.splice(index, 1);
    },
  },
};
</script>

<style lang="scss">
#descriptions-card {
  .description-tab {
    .description-text {
      p {
        margin-top: 0;
        margin-bottom: 1rem;
      }
    }
  }
}
</style>
