<template>
  <b-card id="delete-card" title="Zona de perill">
    <!-- BUTTONS -->
    <b-row>
      <b-col cols="12" md="auto">
        <b-button block variant="danger" @click="confirmDeleteAccommodation">
          Elimina l'allotjament
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BButton } from "bootstrap-vue";
import { notifyError, notifySuccess } from "@/utils/methods";

export default {
  name: "AmenitiesCard",
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
  },
  computed: {
    accommodation() {
      return this.$store.getters["realEstateAccommodation/accommodation"];
    },
  },
  methods: {
    confirmDeleteAccommodation() {
      this.$swal({
        title: "Eliminar allotjament",
        text: "Estàs a punt d'eliminar aquest allotjament, estàs segur/a?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Elimina",
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$swal({
            title: "Pensa-ho bé",
            text: "Estàs segur segur segur, segur segur, segur/a?",
            icon: "error",
            showCancelButton: true,
            confirmButtonText: "Que sí, pesat!",
            cancelButtonText: "He canviat d'idea",
            customClass: {
              confirmButton: "btn btn-danger",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          }).then((finalResult) => {
            if (finalResult.value) this.deleteAccommodation();
          });
        }
      });
    },
    deleteAccommodation() {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch(
          "realEstateAccommodation/deleteAccommodation",
          this.accommodation.uuid
        )
        .then(() => {
          notifySuccess(
            "Allotjament eliminat",
            "L'allotjament ha estat eliminat correctament."
          );
          this.$router.push({
            name: "foravila-real-estate-accommodations-list",
          });
        })
        .catch(() =>
          notifyError(
            "Allotjament no eliminat",
            "Hi ha hagut un error al eliminar l'allotjament."
          )
        )
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>
